<template>
    <div>
        <!-- Form Modal -->
        <b-modal id="modal-season-form" ref="myModal" centered no-close-on-backdrop no-close-on-esc size="md" @ok="submit">
            <!-- Custom Modal Header -->
            <template #modal-header="{ close }">
                <!-- Emulate built in modal header close button action -->
                <h5 v-if="transporter.id === null">Add Transporter</h5>
                <h5 v-if="transporter.id !== null">Edit Transporter</h5>

                <feather-icon class="ml-4 pr-0 cursor-pointer" icon="XIcon" size="16" @click="close()" />
            </template>

            <!-- Custom Modal Footer -->
            <template #modal-footer="{ ok, cancel }">
                <b-button size="md" variant="primary" @click="ok()" :disabled="saving">
                    <b-spinner small class="mr-1" label="Small Spinner" v-if="saving" />
                    <span v-if="!saving">Save</span>
                    <span v-if="saving">Saving...</span>
                </b-button>
                <b-button size="md" @click="cancel()" variant="outline-secondary">
                    Cancel
                </b-button>
            </template>

            <!-- Data Form in Modal Body -->
            <template #default="{ }">
                <validation-observer ref="dataForm" #default="{ invalid }">
                    <b-form ref="form" @submit.stop.prevent="handleCreate">

                        <b-row>
                            <!-- <b-col cols="12">
                                <b-form-group labe-for="Company" label="Company">
                                    <b-form-select size="md" v-model="transporter.company_id">
                                        <b-form-select-option :value="null">-- Select --</b-form-select-option>
                                        <b-form-select-option v-for="company in companies" :key="company.id"
                                            :value="company.id">
                                            {{ company.name.toUpperCase() }}
                                        </b-form-select-option>
                                    </b-form-select>
                                </b-form-group>
                            </b-col> -->

                            <b-col cols="3">
                                <b-form-group labe-for="code" label="Code">
                                    <validation-provider #default="{ errors }" name="code" rules="required">
                                        <b-form-input id="code" name="code" v-model="transporter.code"
                                            :state="errors.length > 0 ? false : null" size="md" value="" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.code">{{
                                            serverErrors.code[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <b-col cols="9">
                                <b-form-group labe-for="name" label="Transporter Name">
                                    <validation-provider #default="{ errors }" name="name" rules="required">
                                        <b-form-input id="name" name="name" v-model="transporter.name"
                                            :state="errors.length > 0 ? false : null" size="md" value="" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.name">{{
                                            serverErrors.name[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                        </b-row>
                        <b-row>
                            <b-col cols="12">
                                <b-form-group labe-for="tin" label="TIN">
                                    <validation-provider #default="{ errors }" name="Tin">
                                        <b-form-input id="tin" name="tin" v-model="transporter.tin"
                                            :state="errors.length > 0 ? false : null" size="md" value="" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.tin">{{
                                            serverErrors.tin[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col cols="6">
                                <b-form-group labe-for="vrn" label="Vat Reg number (VRN)">
                                    <validation-provider #default="{ errors }" name="vrn">
                                        <b-form-input id="vrn" name="vrn" v-model="transporter.vrn"
                                            :state="errors.length > 0 ? false : null" size="md" value="" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.vrn">{{
                                            serverErrors.vrn[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col cols="6">
                                <b-form-group labe-for="busines_licence" label="Busines licence Number">
                                    <validation-provider #default="{ errors }" name="busines_licence">
                                        <b-form-input id="busines_licence" name="busines_licence"
                                            v-model="transporter.busines_licence" :state="errors.length > 0 ? false : null"
                                            size="md" value="" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.busines_licence">{{
                                            serverErrors.busines_licence[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>

                    </b-form>
                </validation-observer>
            </template>
        </b-modal>
        <!-- ./Form Modal To add Fleet -->

        <!-- Form Modal -->
        <b-modal id="modal-season-form" ref="myModalFleet" centered no-close-on-backdrop no-close-on-esc size="md"
            @ok="submitFleet">
            <!-- Custom Modal Header -->
            <template #modal-header="{ close }">
                <!-- Emulate built in modal header close button action -->
                <h5>Add Fleet</h5>

                <feather-icon class="ml-4 pr-0 cursor-pointer" icon="XIcon" size="16" @click="close()" />
            </template>

            <!-- Custom Modal Footer -->
            <template #modal-footer="{ ok, cancel }">
                <b-button size="md" variant="primary" @click="ok()" :disabled="saving">
                    <b-spinner small class="mr-1" label="Small Spinner" v-if="saving" />
                    <span v-if="!saving">Save</span>
                    <span v-if="saving">Saving...</span>
                </b-button>
                <b-button size="md" @click="cancel()" variant="outline-secondary">
                    Cancel
                </b-button>
            </template>

            <!-- Data Form in Modal Body -->
            <template #default="{ }">
                <validation-observer ref="dataForm" #default="{ invalid }">
                    <b-form ref="form" @submit.stop.prevent="handleCreateFleet">

                        <b-row>
                            <b-col cols="6">
                                <b-form-group labe-for="make" label="Make">
                                    <validation-provider #default="{ errors }" name="make" rules="required">
                                        <b-form-input id="make" name="make" v-model="fleet.make"
                                            :state="errors.length > 0 ? false : null" size="md" value="" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.make">{{
                                            serverErrors.make[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col cols="6">
                                <b-form-group labe-for="plateNumber" label="Plate Number">
                                    <validation-provider #default="{ errors }" name="plateNumber" rules="required">
                                        <b-form-input id="plateNumber" name="plateNumber" v-model="fleet.plateNumber"
                                            :state="errors.length > 0 ? false : null" size="md" value="" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.plateNumber">{{
                                            serverErrors.plateNumber[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col cols="12">
                                <b-form-checkbox :checked="fleet.isTrailer" class="custom-control-success"
                                    name="check-button" switch v-model="fleet.isTrailer">
                                    <span class="switch-icon-left">
                                        <feather-icon icon="CheckCircleIcon" />
                                    </span>
                                    <span>Is Trailer</span>
                                </b-form-checkbox>
                            </b-col>
                        </b-row>
                    </b-form>
                    <!-- <pre>{{ fleet }}</pre> -->

                </validation-observer>
            </template>
        </b-modal>
        <!-- ./Form Modal -->

        <b-card no-body class="mb-0">
            <div class="m-2">
                <!-- Table Top -->
                <b-row>
                    <!-- Per Page -->
                    <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <label>Show</label>
                        <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="perPageOptions" :clearable="false"
                            class="per-page-selector d-inline-block mx-50 select-size-sm" />
                        <label>entries</label>
                    </b-col>
                    <!-- ./Per Page -->

                    <!-- Search & Button -->
                    <b-col cols="12" md="6">
                        <div class="d-flex align-items-center justify-content-end">
                            <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Search..."
                                size="md" />
                            <b-button variant="primary" size="md" @click="invokeCreateForm()">
                                <span class="text-nowrap">Add Transporter</span>
                            </b-button>
                        </div>
                    </b-col>
                    <!-- ./Search & Button-->
                </b-row>
                <!-- ./Table Top -->
            </div>

            <!-- Table -->
            <b-table striped hover small ref="records" class="position-relative" :items="fetch" responsive :fields="columns"
                primary-key="id" :sort-by.sync="sortBy" show-empty empty-text="No records found"
                :sort-desc.sync="isSortDirDesc">

                <template #cell(company)="data">
                    <span v-if="data.item.company">{{ data.item.company.name.toUpperCase() }}</span>
                </template>


                <!-- Column: Actions -->
                <template #cell(actions)="data">
                    <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">

                        <template #button-content>
                            <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                        </template>

                        <b-dropdown-item @click="invokeFleetsForm(data.item)">
                            <feather-icon icon="TruckIcon" />
                            <span class="align-middle ml-50">Fleets</span>
                        </b-dropdown-item>

                        <b-dropdown-item @click="invokeUpdateForm(data.item)">
                            <feather-icon icon="EditIcon" />
                            <span class="align-middle ml-50">Edit</span>
                        </b-dropdown-item>

                        <b-dropdown-item @click="confirmDelete(data.item.id)">
                            <feather-icon icon="TrashIcon" />
                            <span class="align-middle ml-50">Delete</span>
                        </b-dropdown-item>
                    </b-dropdown>
                </template>
            </b-table>

            <!-- Table Footer -->
            <div class="mx-2 mb-2">
                <b-row>
                    <b-col cols="12" sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-start">
                        <span class="text-muted">Showing {{ from }} to {{ to }} of {{ totalRecords }} entries</span>
                    </b-col>
                    <!-- Pagination -->
                    <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                        <b-pagination v-model="currentPage" :total-rows="totalRecords" :per-page="perPage" first-number
                            last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </b-col>
                </b-row>
            </div>
        </b-card>
    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
    BRow, BCol, BCard, BCardHeader, BCardBody, BTable, BPagination,
    BForm, BFormGroup,
    BButton, BFormSelect, BFormSelectOption, BFormInput, BDropdown, BDropdownItem,
    BSpinner, BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required } from '@validations'
import store from '@/store'
import { ref, onUnmounted, onMounted } from '@vue/composition-api'
import transportersStoreModule from '@/views/cromis/transportations/transporter/transportersStoreModule'
import useTransportersList from '@/views/cromis/transportations/transporter/useTransportersList'

export default {
    props: {},
    components: {
        BRow, BCol, BCard, BCardHeader, BCardBody, BTable, BPagination,
        BForm, BFormGroup,
        BButton, BFormSelect, BFormSelectOption, BFormInput, vSelect, BDropdown, BDropdownItem,
        BSpinner,
        ValidationObserver, ValidationProvider, BFormCheckbox,
    },
    directives: {},
    setup(props, context) {
        const dataForm = ref(null)
        const myModal = ref(null)
        const myModalFleet = ref(null)
        const saving = ref(false)
        const serverErrors = ref(null)
        const selections = ref([])
        const formSelections = ref([])
        const levels = ref(null)
        const children = ref([])
        const companies = ref([])
        const selectedCompany = ref(null)

        const transporter = ref({
            id: null,
            company_id: null,
            tin: null,
            name: null,
            vrn: null,
            busines_licence: null,
            code: null
        })

        const fleet = ref({
            id: null,
            plateNumber: null,
            make: null,
            isTrailer: 0,
        })

        const CROMIS_STORE_MODULE_NAME = 'cromis-transporter'

        // Register module
        if (!store.hasModule(CROMIS_STORE_MODULE_NAME)) store.registerModule(CROMIS_STORE_MODULE_NAME, transportersStoreModule)

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(CROMIS_STORE_MODULE_NAME)) store.unregisterModule(CROMIS_STORE_MODULE_NAME)
        })

        onMounted(async () => {


            await store.dispatch('cromis-transporter/companies')
                .then(response => {
                    companies.value = response.data.companies
                    console.log(companies.value)
                })
                .catch(error => {
                    console.log('got error', error)
                })
        })



        const {
            fetch,
            columns,
            perPage,
            currentPage,
            totalRecords,
            from,
            to,
            meta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            records,
            refetch,

            // Filers
            locationFilter,
        } = useTransportersList()

        // Form Methods
        const invokeUpdateForm = (item) => {
            serverErrors.value = null


            transporter.value = {
                id: item.id,
                company_id: item.company ? item.company.id : null,
                tin: item.tin,
                name: item.name,
                vrn: item.vrn,
                busines_licence: item.busines_licence,
                code: item.code
            };

            myModal.value.show()
        }

        const invokeFleetsForm = (item) => {
            serverErrors.value = null

            fleet.value = {
                id: null,
                transporter_id: item.id,
                plateNumber: null,
                make: null,
                isTrailer: 0,
            }
            myModalFleet.value.show()
        }

        const invokeCreateForm = () => {
            formSelections.value.map((form) => {
                form.model = null
            })

            transporter.value = {
                id: null,
                company_id: null,
                tin: null,
                name: null,
                vrn: null,
                busines_licence: null,
                code: null
            }

            myModal.value.show()
        }

        const isFormValid = async () => {
            let isValid = false
            await dataForm.value.validate().then(success => {
                isValid = success
            })

            return isValid
        }

        const submit = (bvModalEvt) => {
            bvModalEvt.preventDefault()

            serverErrors.value = null
            // Handle form submit
            if (transporter.value.id === null || transporter.value.id === 0)
                handleCreate()
            else
                handleUpdate(transporter.value)
        }

        const handleCreate = async () => {
            saving.value = true
            const valid = await isFormValid()

            if (!valid) {
                saving.value = false
                return
            }


            await store.dispatch('cromis-transporter/create', transporter.value)
                .then(response => {
                    refetch()
                    saving.value = false

                    myModal.value.hide()

                    context.root.$swal({
                        icon: 'success',
                        text: `The transporter has been added successfully!`,
                        showConfirmButton: true,
                        timer: 5000,
                        customClass: {
                            confirmButton: 'btn btn-primary',
                        },
                        buttonsStyling: false,
                    })
                })
                .catch(error => {
                    saving.value = false
                    if (error.response.status === 422) {
                        serverErrors.value = error.response.data.errors
                    }
                    else {
                        context.root.$swal({
                            icon: 'error',
                            title: 'Server Error',
                            text: 'Something went wrong. See tech support',
                            showConfirmButton: true,
                            customClass: {
                                confirmButton: 'btn btn-danger',
                            },
                            buttonsStyling: false,
                        })
                    }
                })
        }


        const submitFleet = (bvModalEvt) => {
            bvModalEvt.preventDefault()

            serverErrors.value = null
            handelFleetCreate()

        }
        const handleUpdate = async (item) => {
            saving.value = true
            const valid = await isFormValid()

            if (!valid) {
                saving.value = false
                return
            }

            await store.dispatch('cromis-transporter/update', { id: item.id, data: item })
                .then(response => {
                    refetch()
                    saving.value = false

                    myModal.value.hide()

                    context.root.$swal({
                        icon: 'success',
                        text: `Changes to transporter ${response.data.name} has been saved successfully!`,
                        showConfirmButton: true,
                        timer: 5000,
                        customClass: {
                            confirmButton: 'btn btn-success',
                        },
                        buttonsStyling: false,
                    })
                })
                .catch(error => {
                    saving.value = false
                    if (error.response.status === 422) {
                        serverErrors.value = error.response.data.errors
                    }
                    else {
                        context.root.$swal({
                            icon: 'error',
                            title: 'Server Error',
                            text: 'Something went wrong. See tech support',
                            showConfirmButton: true,
                            customClass: {
                                confirmButton: 'btn btn-danger',
                            },
                            buttonsStyling: false,
                        })
                    }
                })
        }

        const handelFleetCreate = async () => {

            saving.value = true
            const valid = await isFormValid()

            if (!valid) {
                saving.value = false
                return
            }


            await store.dispatch('cromis-transporter/fleet', fleet.value)
                .then(response => {
                    refetch()
                    saving.value = false

                    myModal.value.hide()

                    context.root.$swal({
                        icon: 'success',
                        text: `The transporter Fleet has been added successfully!`,
                        showConfirmButton: true,
                        timer: 5000,
                        customClass: {
                            confirmButton: 'btn btn-primary',
                        },
                        buttonsStyling: false,
                    })
                    fleet.value = {
                        id: null,
                        plateNumber: null,
                        make: null,
                        isTrailer: 0,
                    }
                    myModalFlee.value.hide()
                })
                .catch(error => {
                    saving.value = false
                    if (error.response.status === 422) {
                        serverErrors.value = error.response.data.errors
                    }
                    else {
                        context.root.$swal({
                            icon: 'error',
                            title: 'Server Error',
                            text: 'Something went wrong. See tech support',
                            showConfirmButton: true,
                            customClass: {
                                confirmButton: 'btn btn-danger',
                            },
                            buttonsStyling: false,
                        })
                    }
                })
        }

        const confirmDelete = async (id) => {
            const result = context.root.$swal({
                icon: 'info',
                title: 'System Info',
                text: 'Are you sure you want to delete the Transporter?',
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, cancel',
                customClass: {
                    confirmButton: 'btn btn-info',
                    cancelButton: 'btn btn-danger ml-2', // Optional: Customize the cancel button class
                },
                buttonsStyling: true,
            }).then((result) => {

                if (result.value) {
                    remove(id)
                } else {
                    context.root.$swal({
                        icon: 'info',
                        title: 'Cancelled',
                        text: 'Your Transporter is safe you cancell deletion of it',
                        showConfirmButton: true,
                        customClass: {
                            confirmButton: 'btn btn-info',
                        },
                        buttonsStyling: true,
                    })
                }
            })

        }

        const remove = async (id) => {
            console.log(id)
            await store.dispatch('cromis-transporter/remove', id)
                .then(response => {
                    refetch()
                    console.log(response)
                })
                .catch(error => {
                    context.root.$swal({
                        icon: 'error',
                        title: 'Server Error',
                        text: 'Something went wrong. See tech support',
                        showConfirmButton: true,
                        customClass: {
                            confirmButton: 'btn btn-danger',
                        },
                        buttonsStyling: true,
                    })
                })
        }

        return {
            // Data
            selections,
            formSelections,
            levels,
            children,
            transporter,
            fleet,
            fetch,
            columns,
            perPage,
            currentPage,
            totalRecords,
            from,
            to,
            meta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            records,
            refetch,

            // Methods
            required,
            saving,
            serverErrors,
            dataForm,
            myModal,
            myModalFleet,
            locationFilter,
            invokeCreateForm,
            invokeUpdateForm,
            submit,
            submitFleet,
            isFormValid,
            handleCreate,
            handleUpdate,
            remove,
            confirmDelete,
            companies,
            selectedCompany,
            invokeFleetsForm
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
</style>